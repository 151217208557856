* {
    margin: 0;
    padding: 0;
    letter-spacing: 0.01em;
    box-sizing: border-box;
}

html,body{
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    min-height: 100vh;
}


html {
    height: -webkit-fill-available;
}

body {
    min-height: -webkit-fill-available;
}
