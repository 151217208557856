
/*onest-400*/
@font-face {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/onest-regular.woff') format("woff") /*Modern browsers*/, url("../fonts/onest-regular.ttf") format('truetype') /*Safari, Android, iOS*/;
}

/*onest-500*/
@font-face {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/onest-medium.woff") format("woff") /*Modern browsers*/, url("../fonts/onest-medium.ttf") format('truetype') /*Safari, Android, iOS*/;
}

/*onest-700*/
@font-face {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/onest-bold.woff") format("woff") /*Modern browsers*/, url("../fonts/onest-bold.ttf") format('truetype') /*Safari, Android, iOS*/;
}

/*onest-800*/
@font-face {
    font-family: 'Onest';
    font-style: normal;
    font-weight: 800;
    src: url("../fonts/onest-extra-bold.woff") format("woff") /*Modern browsers*/, url("../fonts/onest-extra-bold.ttf") format('truetype') /*Safari, Android, iOS*/;
}

